import One from '../images/artwork/CityMarket/1.jpg'
import Two from '../images/artwork/CityMarket/2.jpg'
import Three from '../images/artwork/CityMarket/3.jpg'
import Four from '../images/artwork/winthropRockefeller/WRI_ArtsInTheNaturalState_001small.jpg'
import Five from '../images/artwork/winthropRockefeller/WRI_ArtsInTheNaturalState_026.jpg'
import Six from '../images/artwork/winthropRockefeller/WRI_ArtsInTheNaturalState_011.jpg'
import Seven from '../images/artwork/winthropRockefeller/WRI_ArtsInTheNaturalState_002.jpg'
import Eight from '../images/artwork/winthropRockefeller/WRI_ArtsInTheNaturalState_026.jpg'

export const photos1 = [

      {
        src: One,
        width: 4,
        height: 3,
        title: "Cloud Canopy, Downtown Kansas City, 2017, sponsored by Art in the Loop, photographed by Roy Inman"
      },
      {
        src: Two,
        width: 3,
        height: 4.5,
        title: "Cloud Canopy, Downtown Kansas City, sponsered by Art in the Loop, 2017"
      },
      {
        src: Three,
        width: 4,
        height: 3,
        title: "Cloud Canopy, Downtown Kansas City, 2017, sponsored by Art in the Loop, photographed by Roy Inman"
      },
      {
        src: Four,
        width: 4,
        height: 3,
        title: "Installation at the Winthrop Rockefeller Institute, Morrilton Arkansas, 2018-2019"
      },
      {
        src: Five,
        width: 4.5,
        height: 3,
        title: "Installation at the Winthrop Rockefeller Institute, Morrilton Arkansas, 2018-2019"
      },
      {
        src: Six,
        width: 4,
        height: 3,
        title: "Installation at the Winthrop Rockefeller Institute, Morrilton Arkansas, 2018-2019"
      },     
      {
        src: Seven,
        width: 4,
        height: 3,
        title: "Installation at the Winthrop Rockefeller Institute, Morrilton Arkansas, 2018-2019"
      },
      {
        src: Eight,
        width: 4,
        height: 3,
        title: "Installation at the Winthrop Rockefeller Institute, Morrilton Arkansas, 2018-2019"
      },

  ];

